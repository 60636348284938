<template>
  <b-sidebar
    id="add-new-feature-sidebar"
    :visible="isAddNewFeatureSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-feature-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("message.functionalities.createNewFunctionalities") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewfeature())"
          @reset.prevent="resetForm"
        >
          <!-- Id -->
          <validation-provider
            #default="validationContext"
            name="cnpj"
            rules="required"
          >
            <b-form-group
              label-for="id"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.functionalities.id") }}
              </template>
              <b-form-input
                id="id"
                v-model="featureData.feature_id"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="nome"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="featureData.name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="descrição"
            rules="required"
          >
            <b-form-group
              label-for="description"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <b-form-input
                id="description"
                v-model="featureData.description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,

    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewFeatureSidebarActive",
    event: "update:is-add-new-feature-sidebar-active",
  },
  props: {
    isAddNewFeatureSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      disabledButton: false,
    };
  },

  methods: {
    submitNewfeature() {
      this.disabledButton = true;
      this.onSubmit();

      axios({
        method: "post",
        url: `${URL_API}feature`,
        headers: getHeader(this.userData),
        data: this.featureData,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.create"),
              icon: "CheckIcon",
              variant: "success",
              text: `Funcionalidade criada com sucesso`,
            },
          });
        })
        .then(() => {
          this.$root.$emit("newListFeature", null);
          this.disabledButton = false;
        })
        .catch((error) => {
          this.disabledButton = false;
          console.log(error);
        });
    },
  },

  setup(props, { emit }) {
    const blankfeatureData = {};

    const featureData = ref(JSON.parse(JSON.stringify(blankfeatureData)));
    const resetfeatureData = () => {
      featureData.value = JSON.parse(JSON.stringify(blankfeatureData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-feature-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetfeatureData);

    return {
      featureData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-feature-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
